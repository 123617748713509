<!-- *************************** 实名认证 ****************************** -->
<template>
  <div class="user_certification">
    <div class="is_verify">
      <p style="text-align:left;font-size:18px;font-weight:600">实名认证</p>
      <p
        style="display:flex;justify-content:space-between;padding:20px 0;border-bottom:1px solid #dcdfe6"
      >
        <span>审核状态：{{ verifyStatus[status] }}</span>
      </p>
    </div>
    <div  class="upload">
      <el-form class="demo-ruleForm">
        <el-form-item label="护照照片:" label-width="100px">
          <el-upload
            name="passport"
            class="avatar-uploader"
            action="/api/user/certification/upload"
            :headers="headers"
            :show-file-list="false"
            :on-success="(res,file)=>handleSuccess(res,file,'PassportUrl')"
            :before-upload="beforeUpload"
          >
            <img v-if="PassportUrl" :src="PassportUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="学生证照片:" label-width="100px">
          <el-upload
            name="student_id_card"
            class="avatar-uploader"
            action="/api/user/certification/upload"
            :headers="headers"
            :show-file-list="false"
            :on-success="(res,file)=>handleSuccess(res,file,'studentIdUrl')"
            :before-upload="beforeUpload"
          >
            <img v-if="studentIdUrl" :src="studentIdUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import { getCertificationInfoApi } from "api/AdminCenter";

export default {
  data() {
    return {
      verifyStatus: {
        0: "待审核",
        1: "已通过",
        2: "未通过"
      },
      isShow: true,
      isVerify: false,
      status: 0,
      PassportUrl: "",
      studentIdUrl: ""
    };
  },
  computed: {
    headers() {
      return { Authorization: "Bearer " + sessionStorage.getItem("token") };
    }
  },
  mounted() {
    this.getCertificationInfo();
  },
  methods: {
    getCertificationInfo() {
      getCertificationInfoApi().then(res => {
        if (res.code === 0 && res.data !== null) {
          this.loading = false;
          const { status,passport,student_id_card } = res.data;
          this.status = status;
          this.isVerify = true;
          this.PassportUrl = passport;
          this.studentIdUrl = student_id_card;
        }
      });
    },
    //上传成功
    handleSuccess(res, file, type) {
      if (res.code == 0) {
        this[type] = res.data;
        this.getCertificationInfo()
      }else {
        this.$message.error(res.message);
      }
    },
    //上传之前
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 4;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 4MB!");
      }
      return isLt2M;
    }
  }
};
</script>
<style lang="scss">
.user_certification {
  padding: 50px;

  .no_verify {
    margin-top: 100px;
  }

  .is_verify {
    .modify_text {
      color: #517cfc;
      cursor: pointer;
    }
  }
  .upload {
    text-align: left;
    margin-top: 30px;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }
  .avatar {
    width: 100px;
    height: 100px;
    display: block;
  }
}
</style>